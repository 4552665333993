const loadingUI = {
  start(message) {
    let node = this.createNodes(message);
    document.body.insertBefore(node, document.body.childNodes[0]);
  },

  stop() {
    let node = document.querySelector(".loading-ui-element__container");
    node.remove();
  },

  createNodes(message) {
    let node = document.createElement("div");
    let container = document.createElement("div");
    let paragraph = document.createElement("p");
    let spinnerHolder = document.createElement("div");

    spinnerHolder.classList.add("spinner-container");
    container.classList.add("loading-ui-element__container");

    paragraph.innerHTML = message;
    spinnerHolder.innerHTML = `<svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
        <path fill="#000" d="M25.251,6.461c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615V6.461z">
            <animateTransform attributeType="xml"
                attributeName="transform"
                type="rotate"
                from="0 25 25"
                to="360 25 25"
                dur="0.6s"
                repeatCount="indefinite"/>
        </path>
     </svg>`;

    container.appendChild(spinnerHolder);
    container.appendChild(paragraph);
    node.appendChild(container);
    node.classList.add("loading-ui-element");

    return node;
  },
};

export default loadingUI;
